import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../axios";
import {
  Button,
  Container,
  FormRow,
  IconList,
  Input,
} from "../components";
import { ROUTES } from "../routes";
import { AdminNavbar } from "../sections";
import { createIcons } from "../util";

const icons = createIcons();

const AddFeature = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    value: "",
    icon: "",
  });

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleIconChange = (value) => {
    if (data.icon === value) {
      setData((prev) => ({ ...prev, icon: '' }));
    } else {
      setData((prev) => ({ ...prev, icon: value }));
    }
  };

  const handleSave = (e) => {
    e.preventDefault();
    api.post("/feature", { ...data }).then((res) => {
      if (res.status === 200) {
        alert("Yeni özellik eklendi. Yönlendiriliyorsunuz...");
        setTimeout(() => {
          navigate(ROUTES.ADMIN);
        }, 1500);
      }
    });
  };

  return (
    <Container fluid>
      <AdminNavbar />
      <Container style={{ marginTop: "4rem" }}>
        <form method="POST" action="/">
          <FormRow>
            <Input
              label="Özellik Adı"
              placeholder="Özellik adını yazın."
              value={data.name}
              name="name"
              onChange={handleChange}
              fluid
            />
            <Input
              label="Özellik Değeri"
              placeholder="Özellik değerini yazın."
              value={data.value}
              name="value"
              onChange={handleChange}
              fluid
            />
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <IconList
              value={data.icon}
              onChange={handleIconChange}
              fluid
              icons={icons}
              label="İkon"
            />
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <Button type="submit" onClick={handleSave} content="Kaydet" fluid />
          </FormRow>
        </form>
      </Container>
    </Container>
  );
};

export default AddFeature;
