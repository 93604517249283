import { useState } from "react";
import api from "../axios";
import { Button, Container, FormRow, Input } from "../components";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";
import { isNil } from "lodash";

const cookies = new Cookies();

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    if (name === "username") {
      setUsername(value);
    }
    if (name === "password") {
      setPassword(value);
    }
  };

  const handleLogin = () => {
    if (!username || !password) {
      alert("Kullanıcı adı ve şifre zorunlu.");
      return;
    }

    api.post('/login', {
      username,
      password,
    }).then(res => {
      if (res.status === 200) {
        const { data } = res;
        const { token } = data || {};
        if (isNil(token)) {
          alert("Giriş başarısız. Bilgileri kontrol edin.");
          return;
        }
        cookies.set(process.env.REACT_APP_COOKIE_NAME, token, {
          path: ROUTES.HOME,
        });
        navigate(ROUTES.ADMIN);
      } else {
        alert("Giriş başarısız. Bilgileri kontrol edin.");
      }
    }).catch(err => {
      alert("Giriş yaparken bir hata oluştu.");
      console.log("Login error: ", err);
    })
  };

  return (
    <Container className="mt-16">
      <FormRow>
        <Input
          fluid
          label="Kullanıcı adı"
          onChange={handleChange}
          name="username"
          value={username}
          placeholder="Kullanıcı adını girin."
        />
        <Input
          fluid
          label="Şifre"
          onChange={handleChange}
          name="password"
          value={password}
          placeholder="Şifrenizi girin."
        />
      </FormRow>
      <FormRow className="my-4">
        <Button type="button" onClick={handleLogin} content="Giriş Yap" fluid />
      </FormRow>
    </Container>
  );
};

export default Login;
