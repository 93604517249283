import axios from "axios";

import Cookies from "universal-cookie";
const cookies = new Cookies();

const api = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    timeout: 30000,
    headers: {
      'Authorization': cookies.get(process.env.REACT_APP_COOKIE_NAME),
    }
  });

export default api;