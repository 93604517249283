import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../axios";
import { Button, Container, FormRow, Input } from "../components";
import { ROUTES } from "../routes";
import { AdminNavbar } from "../sections";

const AddNumber = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    value: "",
  });

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    api.post("/number", { ...data }).then((res) => {
      if (res.status === 200) {
        alert("Yeni numara eklendi. Yönlendiriliyorsunuz...");
        setTimeout(() => {
          navigate(ROUTES.ADMIN);
        }, 1500);
      }
    });
  };

  return (
    <Container fluid>
      <AdminNavbar />
      <Container style={{ marginTop: "4rem" }}>
        <form method="POST" action="/">
          <FormRow>
            <Input
              label="Numara Adı"
              placeholder="Numara adını yazın."
              value={data.name}
              name="name"
              onChange={handleChange}
              fluid
            />
            <Input
              label="Numara"
              placeholder="Numarayı yazın."
              value={data.value}
              name="value"
              onChange={handleChange}
              fluid
            />
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <Button type="submit" onClick={handleSave} content="Kaydet" fluid />
          </FormRow>
        </form>
      </Container>
    </Container>
  );
};

export default AddNumber;
