import logo from "../assets/logo-white.png";

import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full p-8 flex flex-col bg-slate-800">
      <div className="max-w-container flex flex-col justify-start gap-8 mx-auto">
        <h1 className="text-5xl font-bold text-yellow-50">
          {t("about.title")}
        </h1>
        <p className="text-justify text-base leading-5 text-white">
          {t("about.content")}
        </p>
        <img
          src={logo}
          alt={process.env.REACT_APP_NAME}
          className="w-36 self-end"
        />
      </div>
    </div>
  );
};

export default About;
