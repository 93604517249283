import { useTranslation } from "react-i18next";

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <div className="w-container flex flex-col justify-start p-8 mx-auto gap-8">
      <h1 className="text-5xl font-bold">{t("privacyPolicy.title")}</h1>
      <div className="bg-slate-50 p-4 leading-5">
        <p className="leading-6">{t("privacyPolicy.p1")}</p>
        <p className="leading-6">{t("privacyPolicy.p3")}</p>
        <p className="leading-6">{t("privacyPolicy.p3")}</p>
        <p className="leading-6">{t("privacyPolicy.t1")}</p>
        <ul className="list-disc p-4">
          <li className="leading-6">{t("privacyPolicy.p4")}</li>
          <li className="leading-6">{t("privacyPolicy.p5")}</li>
          <li className="leading-6">{t("privacyPolicy.p6")}</li>
          <li className="leading-6">{t("privacyPolicy.p7")}</li>
          <li className="leading-6">{t("privacyPolicy.p8")}</li>
        </ul>
        <p className="leading-5">{t("privacyPolicy.t2")}</p>
        <ul className="list-disc p-4">
          <li className="leading-6">{t("privacyPolicy.p9")}</li>
          <li className="leading-6">{t("privacyPolicy.p10")}</li>
          <li className="leading-6">{t("privacyPolicy.p11")}</li>
          <li className="leading-6">{t("privacyPolicy.p12")}</li>
          <li className="leading-6">{t("privacyPolicy.p13")}</li>
          <li className="leading-6">{t("privacyPolicy.p14")}</li>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
