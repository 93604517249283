import Cookies from 'universal-cookie';
import en from '../assets/gb.svg';
import tr from '../assets/tr.svg';
import i18n from '../i18n.js';

const LanguageSelector = () => {
  const selectLanguage = (lang) => {
    i18n.changeLanguage(lang, () => {
      const cookies = new Cookies();
      cookies.set("i18next", lang, { path: '/', maxAge: 60 * 60 * 24 });      
      console.log("Language changed to ", lang);
    });
  };

  return (
    <div className="flex flex-row gap-2 items-center justify-center">
      <button onClick={() => selectLanguage('en')}><img src={en} className="w-6" alt="EN" /></button>
      <button onClick={() => selectLanguage('tr')}><img src={tr} className="w-6" alt="TR" /></button>
    </div>
  )
};

export default LanguageSelector;