import { Helmet } from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";

import { isAuthenticated } from "../auth";
import { ROUTES } from "../routes";
import { useTranslation } from "react-i18next";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuth = isAuthenticated();
  let location = useLocation();
  const { t } = useTranslation();

  if (!isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} />;
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_NAME} - {t('dashboard')}</title>
      </Helmet>
      <Component {...rest} />
    </>
  );
}

export default ProtectedRoute;