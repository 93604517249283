import { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  FormRow,
  Input,
  Upload,
} from "../components";
import { filter, includes, map } from "lodash";

import { AdminNavbar } from "../sections";
import api from "../axios";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../routes";

const AddCar = () => {
  const navigate = useNavigate();
  const [features, setFeatures] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [data, setData] = useState({
    name: "",
    type: "",
    price: "",
    image: null,
    features: [],
    conditions: [],
  });

  useEffect(() => {
    api.get("/features").then((res) => {
      if (res.status === 200) {
        setFeatures(res.data);
      }
    });
    api.get("/conditions").then((res) => {
      if (res.status === 200) {
        setConditions(res.data);
      }
    });
  }, []);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleFeatureChange = (e, feature) => {
    const { target } = e;
    const { checked } = target;
    setData((prev) => ({
      ...prev,
      features: checked
        ? [...data.features, feature]
        : filter(prev.features, (prevFeature) => feature !== prevFeature),
    }));
  };

  const handleConditionChange = (e, condition) => {
    const { target } = e;
    const { checked } = target;
    setData((prev) => ({
      ...prev,
      conditions: checked
        ? [...data.conditions, condition]
        : filter(
            prev.conditions,
            (prevCondition) => condition !== prevCondition
          ),
    }));
  };

  const isChecked = (items, item) => includes(items, item);

  const handleImageChange = (image) => {
    setData((prev) => ({ ...prev, image }));
  };

  const handleSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", data.image);
    api
      .post("/uploadImage", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        if (res.status === 200) {
          api
          .post("/car", {
            ...data,
            image: res.data.fileName,
          })
          .then((res) => {
            alert("Yeni araç eklendi. Yönlendiriliyorsunuz...")
            setTimeout(() => {
              navigate(ROUTES.ADMIN);
            }, 1500); 
          });
        } else {
          alert("Resim yükleme başarısız.");
        }
      });
  };

  return (
    <Container fluid>
      <AdminNavbar />
      <Container style={{ marginTop: "4rem" }}>
        <form method="POST" action="/">
          <FormRow>
            <Input
              label="Araç İsmi"
              placeholder="Araç ismini yazın..."
              value={data.name}
              name="name"
              onChange={handleChange}
              fluid
            />
            <Input
              label="Araç Türü"
              placeholder="Araç türünü yazın..."
              value={data.type}
              name="type"
              onChange={handleChange}
              fluid
            />
            <Input
              label="Araç Fiyatı"
              placeholder="Araç fiyatını yazın..."
              value={data.price}
              name="price"
              onChange={handleChange}
              fluid
            />
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <div className="flex flex-1 flex-col">
              <p className="font-bold text-xl mb-2">Araç Özellikleri</p>
              {map(features, (feature) => (
                <Checkbox
                  key={feature._id}
                  id={feature._id}
                  label={feature.value}
                  checked={isChecked(data.features, feature._id)}
                  onChange={(e) => handleFeatureChange(e, feature._id)}
                />
              ))}
            </div>
            <div className="flex flex-1 flex-col">
              <p className="font-bold text-xl mb-2">Kullanım Şartları</p>
              {map(conditions, (condition) => (
                <Checkbox
                  key={condition._id}
                  id={condition._id}
                  label={condition.value}
                  checked={isChecked(data.conditions, condition._id)}
                  onChange={(e) => handleConditionChange(e, condition._id)}
                />
              ))}
            </div>
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <Upload
              onChange={handleImageChange}
              content="Resim Yükle"
              image={data.image && URL.createObjectURL(data.image)}
              name="image"
            />
          </FormRow>
          <div className="h-8" />
          <FormRow>
            <Button type="submit" onClick={handleSave} content="Kaydet" fluid />
          </FormRow>
        </form>
      </Container>
    </Container>
  );
};

export default AddCar;
