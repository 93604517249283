import { map } from "lodash";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ROUTES } from "../routes";

import logo from '../assets/logo-white.png';
import LanguageSelector from "../components/LanguageSelector";

const ITEMS = [
  {
    key: "rezervasyon",
    text: 'reservation'
  },
  {
    key: "araclar",
    text: 'vehicles'
  },
  {
    key: "hizmetler",
    text: 'services'
  },
  {
    key: "urunler",
    text: 'products'
  },
];

const Item = ({ text }) => <li className="hvr-grow hover:cursor-pointer">{text}</li>;

const Navbar = () => {
  const { t } = useTranslation();
  return (
    <div className="h-20 w-full bg-primary">
      <div className="max-w-container mx-auto flex flex-row justify-between items-center h-full text-white">
        <h1 className="mx-2"><Link to={ROUTES.HOME}><img src={logo} alt={process.env.REACT_APP_NAME} className="w-32" /></Link></h1>
        <ul className="hidden md:flex flex-row justify-between items-center gap-8 mx-2">
          {map(ITEMS, (item) => (
            <Item key={item.key} text={t(item.text)} />
          ))}
        </ul>
        <LanguageSelector />
      </div>
    </div>
  );
};

export default Navbar;
