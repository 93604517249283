import { map } from "lodash";
import { Link, NavLink } from "react-router-dom";
import { ROUTES } from "../routes";

const ITEMS = [
  {
    key: "arac-ekle",
    to: ROUTES.ADD_CAR,
    text: "Araç Ekle",
  },
  {
    key: "ozellik-ekle",
    to: ROUTES.ADD_FEATURE,
    text: "Özellik Ekle",
  },
  {
    key: "sart-ekle",
    to: ROUTES.ADD_CONDITION,
    text: "Şart Ekle",
  },
  {
    key: 'numara-ekle',
    to: ROUTES.ADD_NUMBER,
    text: 'Numara Ekle'
  },
];

const Item = ({ text, to }) => (
  <li className="hvr-grow hover:cursor-pointer">
    <Link to={to}>{text}</Link>
  </li>
);

const AdminNavbar = () => {
  return (
    <div className="h-20 w-full bg-primary">
      <div className="w-container mx-auto flex flex-row justify-between items-center p-2 h-full text-white shadow-md">
        <h1><NavLink to={ROUTES.ADMIN}>Rental</NavLink></h1>
        <ul className="flex flex-row justify-between items-center gap-8">
          {map(ITEMS, (item) => (
            <Item key={item.key} text={item.text} to={item.to} />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminNavbar;
