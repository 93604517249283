const Input = ({ onChange, name, value, placeholder, label, fluid, ...rest }) => {
  return (
    <div className={`flex flex-col gap-2 ${fluid ? 'flex-1' : 'w-72'}`}>
      {label && <label htmlFor={name} className="font-bold">{label}</label>}
      <input
        name={name}
        className="border rounded-md shadow-md px-4 py-2 text-lg"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default Input;
