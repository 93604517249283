import ContentLoader from 'react-content-loader';

const CarLoader = ({...props}) => {
  return (
    <div className="flex flex-col p-4 border border-card rounded-md w-card shadow-sm">
      <ContentLoader
        speed={2}
        width={450}
        height={500}
        viewBox="0 0 450 500"
        backgroundColor="#f9f9f9"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="25" y="15" rx="5" ry="5" width="350" height="10" /> 
        <rect x="25" y="45" rx="5" ry="5" width="300" height="10" /> 
        <rect x="25" y="75" rx="5" ry="5" width="400" height="200" />

        <rect x="50" y="305" rx="5" ry="5" width="320" height="10" /> 
        <circle cx="30" cy="310" r="8" /> 
        <rect x="50" y="305" rx="5" ry="5" width="320" height="10" /> 
        <circle cx="30" cy="340" r="8" /> 
        <rect x="50" y="335" rx="5" ry="5" width="300" height="10" /> 
        <circle cx="30" cy="370" r="8" /> 
        <rect x="50" y="365" rx="5" ry="5" width="320" height="10" /> 
        <circle cx="30" cy="400" r="8" /> 
        <rect x="50" y="395" rx="5" ry="5" width="300" height="10" />
        <circle cx="30" cy="430" r="8" /> 
        <rect x="50" y="425" rx="5" ry="5" width="300" height="10" />

        <rect x="25" y="455" rx="5" ry="5" width="200" height="80" />
      </ContentLoader>
    </div>
  )
};

export default CarLoader;