import { map } from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../axios";
import { Container, Icon, Section } from "../components";
import { AdminNavbar } from "../sections";

const getData = (path, cb, errorMessage) => {
  return api
    .get(path)
    .then((res) => {
      if (res.status === 200) {
        cb(res.data);
      } else {
        alert(errorMessage);
      }
    })
    .catch((err) => {
      console.log("Data getirilirken hata oluştu.", err);
      alert(errorMessage);
    });
};

const SectionContainer = ({ label, children }) => {
  return (
    <div className="flex flex-col gap-4 flex-1">
      {label && <h2 className="font-bold text-lg">{label}</h2>}
      {children}
    </div>
  );
};

const Admin = () => {
  const navigate = useNavigate();
  const [cars, setCars] = useState([]);
  const [features, setFeatures] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    getData("/cars", (data) => setCars(data), "Arabalar getirilemedi.");
    getData(
      "/features",
      (data) => setFeatures(data),
      "Özellikler getirilemedi."
    );
    getData(
      "/conditions",
      (data) => setConditions(data),
      "Şartlar getirilemedi."
    );
    getData("/numbers", (data) => setNumbers(data), "Numaralar getirilemedi.");
  }, []);

  const removeItem = (type, itemId) => {
    console.log(type, itemId);
    api.post(`/remove/${type}`, { itemId }).then((res) => {
      if (res.status === 200) {
        console.log("Deleted Id:", res.data);
        alert("Silme işlemi başarılı. Yeniden yönlendiriliyorsunuz...");
        setTimeout(() => {
          navigate(0);
        }, 1500);
      }
    }).catch(err => {
      console.log("Silinme sırasında bir hata oluştu.", type, itemId, err);
      alert("Silinme sırasında bir hata oluştu.");
    })
  };

  console.log(cars, features, conditions, numbers);
  return (
    <Container fluid>
      <AdminNavbar />
      <Container className="flex flex-row gap-4 flex-1 mt-12">
        <SectionContainer label="Arabalar">
          <Section
            name="cars"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(cars, (car) => (
                <div className="flex flex-row justify-between items-center">
                  <p>{car.name}</p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem("car", car._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
        <SectionContainer label="Özellikler">
          <Section
            name="features"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(features, (feature) => (
                <div className="flex flex-row justify-between items-center">
                  <p>
                    {feature.name} - {feature.value}
                  </p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem("feature", feature._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
        <SectionContainer label="Şartlar">
          <Section
            name="conditions"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(conditions, (condition) => (
                <div className="flex flex-row justify-between items-center">
                  <p>
                    {condition.name} - {condition.value}
                  </p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem("condition", condition._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
        <SectionContainer label="Numaralar">
          <Section
            name="numbers"
            className="border shadow-md rounded-md flex-1 overflow-y-auto max-h-itemCard"
          >
            <div className="flex flex-col gap-4">
              {map(numbers, (number) => (
                <div className="flex flex-row justify-between items-center">
                  <p>
                    {number.name} - {number.value}
                  </p>
                  <Icon
                    name="trash"
                    stroke="#f56565"
                    role="button"
                    aria-hidden="true"
                    onClick={() => removeItem("number", number._id)}
                  />
                </div>
              ))}
            </div>
          </Section>
        </SectionContainer>
      </Container>
    </Container>
  );
};

export default Admin;
