import { map } from "lodash";
import { useState } from "react";
import api from "../axios";

import { Car } from "../sections";
import { getPhoneNumber } from "../util";

const Cars = ({ data }) => {
  const [currentNumber, setCurrentNumber] = useState('#');

  const handleNumberClick = (cb) => {
    if (currentNumber === '#') {
      api
        .get("/random-number")
        .then((res) => {
          if (res.status === 200) {
            const { data } = res || {};
            if (currentNumber === "#") {
              const parsedNumber = getPhoneNumber(data.number.value);
              setCurrentNumber(`tel:+${parsedNumber}`);
              cb();
            }
          }
        })
        .catch((err) => {
          console.log("Random number could not be fetched.", err);
        });
    } else {
      cb();
    }
  };

  return (
    <div className="flex flex-row justify-center items-start flex-wrap gap-6">
      {map(data, (car) => (
        <Car key={car._id} data={car} currentNumber={currentNumber} onNumberClick={handleNumberClick} />
      ))}
    </div>
  );
};

export default Cars;
