import { Helmet } from "react-helmet";
import { useState } from "react";

import { Navbar, Footer, About } from "../sections";
import { CallButton, Container, Section } from "../components";
import api from "../axios";
import { getPhoneNumber } from "../util";
import { useTranslation } from "react-i18next";

const PublicRoute = ({ component: Component, title, ...rest }) => {
  const { t } = useTranslation();
  const [currentNumber, setCurrentNumber] = useState("#");
  const parsedTitle = title
    ? `${process.env.REACT_APP_NAME} - ${title}`
    : process.env.REACT_APP_NAME;

  const handleNumberClick = (cb, type) => {
    if (currentNumber === '#') {
      api
        .get("/random-number")
        .then((res) => {
          if (res.status === 200) {
            const { data } = res || {};
            if (currentNumber === "#") {
              const parsedNumber = getPhoneNumber(data.number.value);
              const finalNumber =
                type === "whatsapp"
                  ? `https://api.whatsapp.com/send?phone=${parsedNumber}&text=Merhabalar%20ara%C3%A7%20kiralamak%20istiyorum,bilgi%20alabilir%20miyim%20?`
                  : `tel:+${parsedNumber}`;
              setCurrentNumber(finalNumber);
              cb();
            }
          }
        })
        .catch((err) => {
          console.log("Random number could not be fetched.", err);
        });
    } else {
      if (type === 'whatsapp') {
        const actualNumber = currentNumber.startsWith('tel:') ? currentNumber.substring(5) : currentNumber;
        const finalNumber = `https://api.whatsapp.com/send?phone=${actualNumber}&text=Merhabalar%20ara%C3%A7%20kiralamak%20istiyorum,bilgi%20alabilir%20miyim%20?`;
        setCurrentNumber(finalNumber);
      }
      cb();
    }
  };

  return (
    <>
      <Helmet>
        <title>{parsedTitle}</title>
      </Helmet>
      <Container fluid>
        <Section name="navbar" stretched>
          <Container fluid>
            <Navbar />
          </Container>
        </Section>
        <Component {...rest} />
        <Section name="about" stretched>
          <Container fluid>
            <About />
          </Container>
        </Section>
        <Section name="footer" stretched>
          <Container fluid>
            <Footer />
          </Container>
        </Section>
        <CallButton
          position="left"
          text={t('callNow')}
          icon="phone"
          currentNumber={currentNumber}
          onClick={(cb) => handleNumberClick(cb, "general")}
        />
        <CallButton
          position="right"
          text="Whatsapp"
          icon="smartphone"
          color="#25D366"
          onClick={(cb) => handleNumberClick(cb, "whatsapp")}
          currentNumber={currentNumber}
        />
      </Container>
    </>
  );
};

export default PublicRoute;
