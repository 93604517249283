import { useEffect, useState } from "react";
import { If, Else, Then } from "react-if";

import { Container, Section, CarLoader } from "../components";
import { Cars } from "../sections";
import api from "../axios";

const Home = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.get("/cars").then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setCars(res.data);
      }
    });
  }, []);

  return (
    <Section name="cars">
      <Container fluid>
        <If condition={loading}>
          <Then>
            <div className="flex flex-row justify-center items-start flex-wrap gap-6">
              <CarLoader />
              <CarLoader />
              <CarLoader />
              <CarLoader />
              <CarLoader />
              <CarLoader />
            </div>
          </Then>
          <Else>
            <Cars data={cars} />
          </Else>
        </If>
      </Container>
    </Section>
  );
};

export default Home;
