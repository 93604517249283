import React from "react";

const Button = React.forwardRef(({ content, as, onClick, fluid, type, ...rest }, ref) => (
  <button
    as={as}
    ref={ref}
    type={type}
    onClick={onClick}
    className={`bg-secondary text-white rounded-full h-10 shadow-sm hover:shadow-md transition-300 ease-in-out font-bold ${
      fluid ? "w-full" : "w-auto"
    }`}
    {...rest}
  >
    {content}
  </button>
));

export default Button;
