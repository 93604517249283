const Ribbon = ({ content }) => (
  <p
    className="absolute bg-secondary opacity-90 origin-top float-right right-6 top-6 w-32 text-center text-xl text-white"
    style={{ transform: "translateX(50%) rotate(45deg)" }}
  >
    {content}
  </p>
);

export default Ribbon;
