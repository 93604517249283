import { Link } from 'react-router-dom';
import { ROUTES } from '../routes';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex text-center h-36 bg-slate-900 text-yellow-50 items-center justify-center pb-24">
      <p>&copy; 2023 - <Link to={ROUTES.HOME}>{process.env.REACT_APP_NAME}</Link> &middot; <Link to={ROUTES.PRIVACY}>{t('privacyPolicy.title')}</Link></p>
    </div>
  )
};

export default Footer;