import { useRef } from "react";
import { map } from "lodash";

import { Icon, Ribbon } from "../components";
import { useTranslation } from "react-i18next";

const Car = ({ data, currentNumber, onNumberClick }) => {
  const { t } = useTranslation();
  const callRef = useRef();

  const rentNow = () => {
    onNumberClick(() => callRef.current.click());
  };

  return (
    <div className="relative overflow-hidden w-card px-4 py-8 flex flex-col justify-start shadow-md bg-white rounded-md hover:shadow-md hover:cursor-pointer ease-in-out duration-300 border border">
      <p className="text-secondary font-bold">{data.type}</p>
      <p className="text-primary font-bold">{data.name}</p>
      <Ribbon content={`${data.price}₺`} />
      <img src={data.image} alt={data.name} className="my-4" />
      <a
        ref={callRef}
        href={currentNumber}
        onClick={rentNow}
        className="flex justify-center items-center bg-gradient-to-r from-indigo-700 from-10% via-indigo-600 to-indigo-700 to-90% text-white text-center rounded-full h-10 shadow-sm hover:shadow-md transition-300 ease-in-out font-bold my-4"
      >
        {t('rentNow')}
      </a>
      <div className="flex flex-row items-start p-4 border shadow-sm rounded-md">
        <div className="flex flex-1 flex-col gap-4">
          <p className="font-bold text-primary">{t('carFeatures')}</p>
          <div className="flex flex-col gap-4">
            {map(data.features, (feature) => (
              <div
                key={feature.name}
                className="flex flex-row items-center gap-1"
              >
                {feature.icon ? (
                  <Icon name={feature.icon} />
                ) : (
                  <Icon name="check" />
                )}
                {feature.value}
              </div>
            ))}
          </div>
        </div>
        <div className="h-[120px] w-[1px] bg-line mx-4 self-center" />
        <div className="flex flex-1 flex-col gap-4">
          <p className="font-bold text-primary">{t('rentalConditions')}</p>
          <div className="flex flex-col gap-4">
            {map(data.conditions, (condition) => (
              <div
                key={condition.name}
                className="flex flex-row items-center justify-start gap-2"
              >
                {condition.icon && (
                  <img src={condition.icon} alt={condition.value} />
                )}
                {condition.value}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Car;
